<template>
    <div class="notification-card">

        <div class="notification-content">
            <div>
                <p class="card__info__title">{{item.name}}</p>

                <p v-html="item.message"></p>
            </div>

            <div class="date-card">
                Date Changed: {{item.date}}
            </div>

            <div class="position-clear-icon">
                <button class="clear-notification float-right" @click="changeStatus">
                    <Tooltip tooltipText="Mark as read" position="left" cClass="w150 t0" v-if="!item.status">
                        <img src="@/assets/svg/complete.svg" alt="Marked as read icon" />
                    </Tooltip>
                    <Tooltip tooltipText="Mark as new" position="left" cClass="w150 t0" v-else>
                        <img src="@/assets/svg/complete-close.svg" alt="Marked as new icon" />
                    </Tooltip>
                </button>
            </div>
        </div>

        <div v-if="createError">{{ createError }}</div>
    </div>
</template>

<script>
    import Tooltip from "./tooltip";
    import {useStore} from "vuex";
    import RequestHandler from "@/handler/RequestHandler";
    import {onMounted, ref} from "vue";
    export default {
        name: 'Notification-card',
        components: {
            Tooltip
        },
        props: [
            'item'
        ],
        emits: ['change'],
        setup(props, {emit}) {
            const store = useStore();
            const createError = ref(null);

            const changeStatus = () => {
                RequestHandler.updateNotificationItemStatus(
                    props.item.id,
                    store.getters.csrf
                )
                    .then(data => {
                        emit('change', data);
                    })
                    .catch(error => {
                        console.error(error);
                        createError.value = error.message || error;
                    });
            };
            const colored = ( clas, color) => {
                let redColor =  document.getElementsByClassName(clas)
                for(var i=0; i<redColor.length;i++){
                    redColor[i].addClass(color);
                }
            }
            onMounted(() =>{
                colored('prev-version', 'red')
                colored('new-version', 'green')
            })


          //  console.log('red>>', rrred)
            return {
                changeStatus,
                createError,
                colored,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";

    .notification-card {
        padding: 1.5rem;
        background-color: white;
        border-radius: .25rem;
        box-shadow: 0 0.5em 1em -0.125em rgba(0,0,0,.1), 0 0 0 1px rgba(0,0,0,.02);
        margin-bottom: 20px;
    }

    .float-right {
        float: right;
    }

    .position-clear-icon {
        position: relative;
        top: -20px;
        left: 15px;
    }

    @include block('card') {
        @include element('info') {
            @include element('title') {
                display: block;
                margin-bottom: 15px;
                font-weight: 600;
                line-height: 1.125;
                font-size: 2em;
                font-family: $header;
                text-decoration: none;
                display: flex;
            }
        }
    }

    .clear-notification {
        cursor: pointer;
        background-color: transparent;
        border: none;
    }

    .red {
        color: $red;
    }
    .green {
        color: $green--light;
    }
</style>
