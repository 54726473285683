<template>
    <!-- Page: pages/my-notifications -->
    <br/>

    <breadcrumbs :items="path"/>

    <br/>

    <div class="header-notification">
        <div class="container dfw">
            <div>
                <h1>My Notifications</h1>
            </div>
           <div class="settings-link">
                <app-button icon="settings" @click="$router.push('/my-notification-settings')">Settings</app-button>
           </div>
            <!--<user-tools/>-->
        </div>

        <p class="description-head">Updates to items you've chosen to follow will show up in this notification list. Filter by type using the filter set below.</p>

        <hr />

    </div>

    <general-container customClass="my-notifications">
        <div class="columns is-tablet-custom">
            <div class="column is-one-third">
                <!--left side options-->
                <div class="filter-left">
                    <div class="left-card">
                        <div class="card-content">
                            <div class="content">
                                <div class="search-filter-group">
                                    <div>
                                        <p class="content-block__title h3 search-filter-name">Filter by content types</p>
                                        <div class="content-block__content">
                                            <ul>
                                                <li v-for="contentType in contentTypes">
                                                    <div class="field">
                                                        <input :id="'contentType-' + contentType.value"
                                                            class="switch is-rounded"
                                                            v-on:click="clickContentType($event)"
                                                            type="checkbox"
                                                            :value="contentType.value"
                                                            :checked="setContentTypes.indexOf(contentType.value) !== -1"
                                                        >
                                                        <label :for="'contentType-' + contentType.value"
                                                            class="agency-filter-label">{{ contentType.name }}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="card-content" v-if="agencies">
                            <div class="content">
                                <div class="search-filter-group">
                                    <div>
                                        <p class="content-block__title h3 search-filter-name">Filter by agencies</p>
                                        <div class="content-block__content">
                                            <ul>
                                                <li v-for="agency in agencies.filter(x => x.name !== 'Legislative Branch' && x.name !== 'Administered Funds')">
                                                    <div class="field">
                                                        <input :id="'agency-' + agency.contentId"
                                                            class="switch is-rounded"
                                                            v-on:click="clickFilter($event)" type="checkbox"
                                                            :checked="agencyIds.indexOf(agency.contentId) !== -1"
                                                            :value="agency.contentId"
                                                        >
                                                        <label :for="'agency-' + agency.contentId"
                                                            class="agency-filter-label">{{ agency.name }}</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end left side options-->
            </div>

            <div class="column is-two-thirds">
                <div  class="filter-body">
                    <!--body notification-->
                    <div class="filter-head">
                        <div>
                            <div class="card date-range-card">
                                <div class="card-content--white">
                                    <label class="label h3">Filter by Date Range: </label>
                                    <div class="date-set">
                                        <div class="columns">
                                            <div class="column">
                                                <span class="control-title">From Date:</span>
                                                <div class="control">
                                                    <div class="control-icon">
                                                        <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                                    </div>
                                                    <div class="control-input">
                                                        <Datepicker
                                                            v-model="dateStart"
                                                            :clearable="true"
                                                            :inputFormat="'MM/dd/yyyy'"
                                                            :typeable="true"
                                                            :placeholder="'mm/dd/yyyy'"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="column">
                                                <span class="control-title">To Date:</span>
                                                <div class="control">
                                                    <div class="control-icon">
                                                        <font-awesome-icon :icon="faCalendarDay" aria-label="Calendar icon"></font-awesome-icon>
                                                    </div>
                                                    <div class="control-input">
                                                        <Datepicker
                                                            v-model="dateEnd"
                                                            :clearable="true"
                                                            :inputFormat="'MM/dd/yyyy'"
                                                            :typeable="true"
                                                            :placeholder="'mm/dd/yyyy'"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="new-cleared-container">
                                <div class="button-row">
                                    <div class="text-center">
                                        <button :class="'button btn btn--primary notification-search' + (!cleared ? ' active': '')"
                                                @click="setCleared(false);">New</button>
                                        <button :class="'button btn btn--primary notification-search' + (cleared ? ' active': '')"
                                                @click="setCleared(true);">Cleared</button>
                                    </div>
                                    <div class="clear-all-btn">
                                        <button class="underline clear-all-btn" @click="clearAll();"><img src="@/assets/svg/complete.svg" alt="Marked as read icon" /><span class="underline">Clear All</span></button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <br/>

                    <!--end body filter-->

                    <div class="list-notification">
                        <preloader v-if="notificationsLoading"></preloader>

                        <template v-if="notifications.length === 0">
                            <div class="no-notifications">
                                No current notifications.
                            </div>
                        </template>
                        <template v-else>
                            <template v-for="item in notifications">
                                <notification-card
                                    :item="item"
                                    @change="itemStatusChanged"
                                />
                            </template>
                        </template>
                        <!--<notification-card item="info"/>-->
                    </div>

                    <pagination :current="page" :page-count="pageCount" v-on:paginated="paginate($event)" v-if="pageCount > 1"/>
                </div>
            </div>
        </div>
    </general-container>

</template>

<script>
import UserTools from "./user-tools.vue";
import AppButton from "@/alivue/components/app-button";
import Breadcrumbs from "./breadcrumbs.vue";
import GeneralContainer from "./general-container.vue";
import Preloader from "./preloader.vue";
import {onMounted, ref, watch} from "vue";
import Datepicker from "vue3-datepicker";
import NotificationCard from "./notification-card.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { faCalendarDay } from '@fortawesome/pro-duotone-svg-icons';
import Pagination from "./pagination.vue";
import RequestHandler from "@/handler/RequestHandler";
import {useStore} from "vuex";
export default {
    data () {
      return {faCalendarDay: faCalendarDay,}
    },
    methods: {},
    components: {
        Pagination,
        NotificationCard,
        Datepicker,
        Preloader,
        GeneralContainer,
        Breadcrumbs,
        UserTools,
        AppButton,
        FontAwesomeIcon
    },
    mounted() {},
    computed: {
        agencies: function () {
            return this.$store.getters.agencies;
        },
    },
    setup() {
        const store = useStore();
        const agencyIds = ref([]);
        const cleared = ref(false);
        const page = ref(1);
        const pageCount = ref(1);
        const itemsPerPage = 10;
        const totalNotificationsCount = ref(0);
        const notificationsLoading = ref(false);
        const notifications = ref([]);
        const dateStart = ref();
        const dateEnd = ref();
        const setContentTypes = ref([]);
        const contentTypes = [
            {name: 'Agencies', value: 'agency'},
            {name: 'Budget Entities', value: 'budget'},
            {name: 'Contacts', value: 'contact'},
            {name: 'Contracts', value: 'contract'},
            {name: 'Positions', value: 'position'},
            {name: 'Procurements', value: 'procurement'},
            {name: 'Vendors', value: 'vendor'},
        ];

        const loadNotificationItems = (reset) => {
            if (reset) {
                page.value = 1;
            }

            notificationsLoading.value = true;
            notifications.value = [];
            let start = '';
            if (dateStart.value) {
                start = dateStart.value.toISOString().split('T')[0];
            }
            let end = '';
            if (dateEnd.value) {
                end = dateEnd.value.toISOString().split('T')[0];
            }
            RequestHandler.loadNotificationItems(
                cleared.value ? 1 : 0,
                itemsPerPage,
                (page.value - 1) * itemsPerPage,
                agencyIds.value,
                setContentTypes.value,
                start,
                end
            )
                .then(data => {
                    notificationsLoading.value = false;
                    totalNotificationsCount.value = data.totalCount || 0;
                    notifications.value = data.list || [];
                    pageCount.value = data.pageCount || 1;
                })
                .catch(error => {
                    console.error(error);
                    totalNotificationsCount.value = 0;
                    notifications.value = [];
                    notificationsLoading.value = false;
                });
        };

        const clearNotifications = (notificationIds) => {
            RequestHandler.clearAllNotificationItems(
                notificationIds,
                store.getters.csrf
            )
                .then(data => {
                    loadNotificationItems(true);
                })
                .catch(error => {
                    console.error(error);
                });
        };

        const itemStatusChanged = () => {
            loadNotificationItems(true);
        };

        onMounted(() => {
            loadNotificationItems();
        });

        const clickFilter = (event) => {
            let agencyId = event.target.value;
            if (event.target.checked){
                agencyIds.value.push(agencyId);
            } else {
                let index = agencyIds.value.indexOf(agencyId);
                if (index !== -1) {
                    agencyIds.value.splice(index, 1);
                }
            }

            loadNotificationItems(true);
        };

        const clickContentType = (event) => {
            let contentType = event.target.value;
            if (event.target.checked){
                setContentTypes.value.push(contentType);
            } else {
                let index = setContentTypes.value.indexOf(contentType);
                if (index !== -1) {
                    setContentTypes.value.splice(index, 1);
                }
            }

            loadNotificationItems(true);
        }

        const setCleared = (value) => {
            cleared.value = value;
            loadNotificationItems(true);
        };

        const clearAll = () => {
            if (!cleared.value && notifications.value.length !== 0) {
                clearNotifications(notifications.value.map(item => item.id))
            }
        };

        watch(dateStart, (now) => {
            loadNotificationItems(true);
        });

        watch(dateEnd, (now) => {
            loadNotificationItems(true);
        });

        const paginate = (pageTo) => {
            page.value = pageTo;
            loadNotificationItems();
        };

        return {
            agencyIds,
            notificationsLoading,
            path: [{ title: 'My Notifications' }],
            page,
            pageCount,
            contentTypes,
            setContentTypes,
            cleared,
            setCleared,
            clearAll,
            paginate,
            notifications,
            dateStart,
            dateEnd,
            clickFilter,
            clickContentType,
            itemStatusChanged
        }
    }
}

</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_variables.scss";
    @import "../../assets/scss/mixins/_bemify.scss";
    .agency-filter-label {
        height: auto !important;
        margin-bottom: 6px;

        &:hover, &:active, &:focus {
            opacity: 0.75;
        }
    }

    li {
        margin: 10px 0;
    }

    .header-notification {
        margin-bottom: 25px;
    }

    .settings-link {
        float: right;
    }

    .button-row {
        padding: 10px 0 0 0;
        display: flow-root;
    }
    .card-content {
        background-color: $blue--dark;
        p, label {
            color: $white;
        }
    }

    .card-content--white {
        background-color: transparent !important;
    }

    .search-filter-group {
        margin: 0 auto;
        margin-bottom: 50px;
        ul {
            list-style-type: none;
            margin-left: 0px;
        }
    }
    .search-filter-name {
        padding-bottom: 10px;
        font-weight: 600;
        font-family: $header;
        margin-bottom: 0px;
        font-size: 28px;
        border-bottom: 1px solid $blue--med;
    }

    .h3 {
        font-size: 28px;
        line-height: 1.25em;
    }

    .date-range-card {
        padding: 1.5rem;
        margin-bottom: 30px;
        .card-content {
            padding: 0px !important;
        }
        .control {
            margin-bottom: 10px;
            display: flex;
            .control-icon {
                float: left;
                padding-right: 10px;
                color: $blue--dark;
                font-size: 32px;
            }
            .control-input {
                width: 100%;
                float: left;
                position: relative;
                input {
                    width: 100%;
                }
            }
        }
        .control-title {
            display: block;
            width: 100%;
        }
    }

    .text-center {
        text-align: center;
    }

    .notification-search {
        width: 150px;
        color: $grey--dark;
        background-color: $grey--med;
        border: 2px solid $primary;
        &.active {
            color: $white;
            background-color: $primary;
        }
        &:first-of-type {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: none;
        }
        &:last-of-type {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0;
        }
    }

    .clear-all-btn {
        position: relative;
        right: 30px;
        bottom: 18px;
        float: right;
        border: none;
        background: none;
        cursor: pointer;
        img {
            margin-right: 5px;
        }
        span {
            color: $primary;
            position: absolute;
            top: 7px;
            width: 60px;
        }
    }

    .underline {
        text-decoration: underline;
    }

    .is-tablet-custom {
        @media screen and (min-width: 769px) {
            display: flex;
        }
    }

    .no-notifications {
        text-align: center;
        font-size: 21px;
        font-weight: bold;
        color: $primary;
    }
</style>
